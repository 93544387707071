import React from 'react';

class Socials extends React.Component {
    render() {
        return (
            <div>
                <div>
                    <p> Email: ajaysingh7161@gmail.com </p>
                    <a href="https://www.instagram.com/floppyflicks/">Instagram</a>
                </div>
            </div>
        );
    }
}

export default Socials;