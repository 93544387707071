import React from 'react';

class AboutMe extends React.Component {
    render() {
        return (
            <div>
                <p>Ajay Singh</p>
                <p>Student at Rochester Institute of Technology</p>
                <p>Major: Software Engineering</p>
            </div>
        );
    }
}

export default AboutMe;